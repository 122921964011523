import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';

const SignedInLinks = (props) => {
    return (
        <ul className="right">
            <li>
                <NavLink to='/create'>New Product</NavLink>
            </li>
            <li>
                <NavLink to='/' onClick={props.signOut}>
                    Déconnexion
                </NavLink>
            </li>
            <li>
                <NavLink to='/profil' className="btn btn-floating green darken-1">
                    {props.profile.initials}
                </NavLink>
            </li>
        </ul>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}


export default connect(
    null,
    mapDispatchToProps
)(SignedInLinks);