import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, NavLink } from 'react-router-dom';
import { editUser } from '../../store/actions/authActions';
import logo from '../../logo.svg';

class EditAccount extends Component {
    constructor(props) {
        super(props);
        const { auth, profile } = this.props;
        if (!auth.uid)
            return <Redirect to='/' />
        console.log(profile);
        this.state = {
            email: profile.email,
            firstName: profile.firstName,
            lastName: profile.lastName,
            uid: auth.uid,
            country: profile.country,
            city: profile.city,
            street: profile.street,
            postal: profile.postal
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.editUser(this.state);
    }

    render() {
        const { auth, profile, authError } = this.props;
        
        if (!auth.uid)
            return <Redirect to='/' />
        return (
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col s10 offset-s1 account-header">
                            <div className="account-title">
                                <h2>Editer mon compte</h2>
                                <img className="brand-filigrane" src={logo} alt="Logo" />
                            </div>
                            <div className="account-tabs">
                                <ul>
                                    <li className="is-active">
                                        <NavLink to='/profil'>
                                            Compte
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/wishlist'>
                                            Wishlist
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/panier'>
                                            Panier
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/commandes'>
                                            Commande(s)
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="col s5">
                                <div className="flat-card profile-card is-auto">
                                    <div className="card-body">
                                        <div className="profile-image">
                                            <img src="https://image.ibb.co/b04PYv/placeholder_w.jpg" alt="avatar" />
                                        </div>
                                        <div className="username has-text-centered">
                                            <span>{profile.firstName} {profile.lastName}</span>
                                        </div>
                                    </div>
                                    <div className="profile-footer has-text-centered">
                                        <span className="achievement-title">KM économisés</span>
                                        <div className="count">
                                            2422 km
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col s7">
                                <div className="flat-card profile-info-card is-auto">     
                                    <div className="card-title">
                                        <h5>Détails du compte</h5>
                                    </div>
                                    <form onSubmit={this.handleSubmit} className="white">
                                        <div className="card-body">
                                            <div className="input-field">
                                                <p>Votre prénom</p>
                                                <input type="text" id="firstName" value={this.state.firstName} onChange={this.handleChange} />
                                            </div>
                                            <div className="input-field">
                                                <p>Votre nom</p>
                                                <input type="text" id="lastName" value={this.state.lastName} onChange={this.handleChange} />
                                            </div>
                                            <div className="input-field">
                                                <p>E-mail</p>
                                                <input type="email" id="email" value={this.state.email} onChange={this.handleChange} />
                                            </div>
                                            <div className="input-field">
                                                <button className="login-form-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-check feather-icons"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                </button>
                                                <div className="red-text center">
                                                    { authError ? <p>{ authError }</p> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col s12">
                                <div className="flat-card profile-info-card is-auto">     
                                    <div className="card-title">
                                        <h5>Adresse de livraison</h5>
                                    </div>
                                    <form onSubmit={this.handleSubmit} className="white">
                                        <div className="card-body">
                                            <div className="input-field">
                                                <p>Pays</p>
                                                <input type="text" id="country" value={this.state.country} onChange={this.handleChange} />
                                            </div>
                                            <div className="input-field">
                                                <p>Ville</p>
                                                <input type="text" id="city" value={this.state.city} onChange={this.handleChange} />
                                            </div>
                                            <div className="input-field">
                                                <p>Rue</p>
                                                <input type="text" id="street" value={this.state.street} onChange={this.handleChange} />
                                            </div>
                                            <div className="input-field">
                                                <p>Code postal</p>
                                                <input type="text" id="postal" value={this.state.postal} onChange={this.handleChange} />
                                            </div>
                                            <div className="input-field">
                                                <button className="login-form-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-check feather-icons"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                </button>
                                                <div className="red-text center">
                                                    { authError ? <p>{ authError }</p> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        authError: state.auth.authError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        editUser: (user) => dispatch(editUser(user))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditAccount);