import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, NavLink } from 'react-router-dom';
import logo from '../../logo.svg';

class Order extends Component {
    render() {
        const { auth } = this.props;
        
        if (!auth.uid)
            return <Redirect to='/' />
        return (
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col s10 offset-s1 account-header">
                            <div className="account-title">
                                <h2>Mes commandes</h2>
                                <img className="brand-filigrane" src={logo} alt="Logo" />
                            </div>
                            <div className="account-tabs">
                                <ul>
                                    <li>
                                        <NavLink to='/profil'>
                                            Compte
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/wishlist'>
                                            Wishlist
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/panier'>
                                            Panier
                                        </NavLink>
                                    </li>
                                    <li className="is-active">
                                        <NavLink to='/commandes'>
                                            Commande(s)
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <div className="col s4">
                                    <div className="flat-card order-card has-popover-top">
                                        <div className="order-info">
                                            <span><a href="invoice.html" onclick="return true">COMMANDE-46895</a></span>
                                            <span className="tag is-primary">Livraison</span>
                                        </div>
                                        <div className="circle-chart-wrapper">
                                            <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" width="140" height="140" xmlns="http://www.w3.org/2000/svg">
                                                <circle className="circle-chart-background" stroke="#efefef" strokeWidth="2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431"></circle>
                                                <circle className="circle-chart-circle" stroke="#0023ff" strokeWidth="2" stroke-dasharray="66,100" stroke-linecap="round" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431"></circle>
                                            </svg>
                                            <div className="chart-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-truck"><rect x="1" y="3" width="15" height="13"></rect><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon><circle cx="5.5" cy="18.5" r="2.5"></circle><circle cx="18.5" cy="18.5" r="2.5"></circle></svg>
                                            </div>
                                            <div className="ring-title has-text-centered">
                                                <span>66% Complété</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s4">
                                    <div className="flat-card order-card has-popover-top">
                                        <div className="order-info">
                                            <span><a href="invoice.html" onclick="return true">COMMANDE-47421</a></span>
                                            <span className="tag is-success">En préparation</span>
                                        </div>
                                        <div className="circle-chart-wrapper">
                                            <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" width="140" height="140" xmlns="http://www.w3.org/2000/svg">
                                                <circle className="circle-chart-background" stroke="#efefef" strokeWidth="2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431"></circle>
                                                <circle className="circle-chart-circle" stroke="#00b289" strokeWidth="2" stroke-dasharray="33,100" stroke-linecap="round" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431"></circle>
                                            </svg>
                                            <div className="chart-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-package"><path d="M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z"></path><polyline points="2.32 6.16 12 11 21.68 6.16"></polyline><line x1="12" y1="22.76" x2="12" y2="11"></line><line x1="7" y1="3.5" x2="17" y2="8.5"></line></svg>
                                            </div>
                                            <div className="ring-title has-text-centered">
                                                <span>33% Complété</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s4">
                                    <div className="flat-card order-card has-popover-top">
                                        <div className="order-info">
                                            <span><a href="invoice.html" onclick="return true">COMMANDE-42987</a></span>
                                            <span className="tag is-warning">En attente</span>
                                        </div>
                                        <div className="circle-chart-wrapper">
                                            <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" width="140" height="140" xmlns="http://www.w3.org/2000/svg">
                                                <circle className="circle-chart-background" stroke="#efefef" strokeWidth="2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431"></circle>
                                                <circle className="circle-chart-circle" stroke="#eda514" strokeWidth="2" stroke-dasharray="10,100" stroke-linecap="round" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431"></circle>
                                            </svg>
                                            <div className="chart-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                            </div>
                                            <div className="ring-title has-text-centered">
                                                <span>10% Complété</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s4">
                                    <div className="flat-card order-card has-popover-top">
                                        <div className="order-info">
                                            <span><a href="invoice.html" onclick="return true">COMMANDE-45654</a></span>
                                            <span className="tag is-danger">Problème</span>
                                        </div>
                                        <div className="circle-chart-wrapper">
                                            <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" width="140" height="140" xmlns="http://www.w3.org/2000/svg">
                                                <circle className="circle-chart-background" stroke="#efefef" strokeWidth="2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431"></circle>
                                                <circle className="circle-chart-circle" stroke="#FF7273" strokeWidth="2" stroke-dasharray="40,100" stroke-linecap="round" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431"></circle>
                                            </svg>
                                            <div className="chart-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-alert-octagon"><polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12" y2="16"></line></svg>  
                                            </div>
                                            <div className="ring-title has-text-centered">
                                                <span>40% Complété</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s4">
                                    <div className="flat-card order-card has-popover-top">
                                        <div className="order-info">
                                            <span><a href="invoice.html" onclick="return true">COMMANDE-42536</a></span>
                                            <span className="tag is-primary">Livraison</span>
                                        </div>
                                        <div className="circle-chart-wrapper">
                                            <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" width="140" height="140" xmlns="http://www.w3.org/2000/svg">
                                                <circle className="circle-chart-background" stroke="#efefef" strokeWidth="2" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431"></circle>
                                                <circle className="circle-chart-circle" stroke="#0023ff" strokeWidth="2" stroke-dasharray="100,100" stroke-linecap="round" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431"></circle>
                                            </svg>
                                            <div className="chart-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-award"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg>
                                            </div>
                                            <div className="ring-title has-text-centered">
                                                <span>100% Complété</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
    }
}

export default connect(
    mapStateToProps
)(Order);