import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, NavLink } from 'react-router-dom';
import logo from '../../logo.svg';

class Wishlist extends Component {
    render() {
        const { auth, profile } = this.props;
        
        if (!auth.uid)
            return <Redirect to='/' />
        return (
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col s10 offset-s1 account-header">
                            <div className="account-title">
                                <h2>Ma wishlist</h2>
                                <img className="brand-filigrane" src={logo} alt="Logo" />
                            </div>
                            <div className="account-tabs">
                                <ul>
                                    <li>
                                        <NavLink to='/profil'>
                                            Compte
                                        </NavLink>
                                    </li>
                                    <li className="is-active">
                                        <NavLink to='/wishlist'>
                                            Wishlist
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/panier'>
                                            Panier
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/commandes'>
                                            Commande(s)
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="col s5">
                                <div className="flat-card profile-card is-auto">
                                    <div className="card-body">
                                        <div className="profile-image">
                                            <img src="https://image.ibb.co/b04PYv/placeholder_w.jpg" alt="avatar" />
                                        </div>
                                        <div className="username has-text-centered">
                                            <span>{profile.firstName} {profile.lastName}</span>
                                        </div>
                                    </div>
                                    <div className="profile-footer has-text-centered">
                                        <span className="achievement-title">KM économisés</span>
                                        <div className="count">
                                            2422 km
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col s7">
                                <div class="flat-card profile-info-card is-auto">     
                                    <div class="card-title">
                                        <h5>Détails du compte</h5>
                                        <div class="edit-account has-simple-popover popover-hidden-mobile" data-content="Edit Account" data-placement="top">
                                            <NavLink to='/edit-account'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings feather-icons"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="col s6">
                                            <div class="info-block">
                                                <span class="label-text">Prénom</span>
                                                <span class="label-value">{profile.firstName}</span>
                                            </div>
                                            <div class="info-block">
                                                <span class="label-text">E-mail</span>
                                                <span class="label-value">{profile.email}</span>
                                            </div>
                                        </div>
                                        <div class="col s6">
                                            <div class="info-block">
                                                <span class="label-text">Nom</span>
                                                <span class="label-value">{profile.lastName}</span>
                                            </div>
                                            <div class="info-block">
                                                <span class="label-text">Téléphone</span>
                                                <span class="label-value">052697564</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12">
                                <div class="flat-card profile-info-card is-auto">     
                                    <div class="card-title">
                                        <h5>Votre adresse</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="col s6">
                                            <div class="info-block">
                                                <span class="label-text">Pays</span>
                                                <span class="label-value">France</span>
                                            </div>
                                            <div class="info-block">
                                                <span class="label-text">Ville</span>
                                                <span class="label-value">Nancy</span>
                                            </div>
                                        </div>
                                        <div class="col s6">
                                            <div class="info-block">
                                                <span class="label-text">Rue</span>
                                                <span class="label-value">45 rue Saint-Jean</span>
                                            </div>
                                            <div class="info-block">
                                                <span class="label-text">Code postal</span>
                                                <span class="label-value">54000</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(
    mapStateToProps
)(Wishlist);