import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Dashboard from './components/dashboard/Dashboard';
import ProductDetails from './components/products/ProductDetails';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import CreateProduct from './components/products/CreateProduct';
import Profile from './components/profiles/Profile';
import Wishlist from './components/profiles/Wishlist';
import Cart from './components/profiles/Cart';
import Order from './components/profiles/Order';
import EditAccount from './components/profiles/EditAccount';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route path='/product/:id' component={ProductDetails} />
            <Route path='/signin' component={SignIn} />
            <Route path='/signup' component={SignUp} />
            <Route path='/create' component={CreateProduct} />
            <Route path='/profil' component={Profile} />
            <Route path='/wishlist' component={Wishlist} />
            <Route path='/panier' component={Cart} />
            <Route path='/commandes' component={Order} />
            <Route path='/edit-account' component={EditAccount} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;