import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signIn } from '../../store/actions/authActions';
import { Redirect, NavLink } from 'react-router-dom';

class SignIn extends Component {
    state = {
        email: '',
        password: ''
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signIn(this.state);
    }
    
    render() {
        const { authError, auth } = this.props;

        if (auth.uid)
            return <Redirect to='/' />

        return (
            <div className="container">
                <div className="row">
                    <form onSubmit={this.handleSubmit} className="white sign-form">
                        <h5 style={{ paddingBottom: 10 }} className="grey-text text-darken-3">Connexion<br/></h5>
                        <div className="input-field">
                            <label htmlFor="email">E-mail</label>
                            <input type="email" id="email" onChange={this.handleChange} />
                        </div>
                        <div className="input-field">
                            <label htmlFor="password">Mot de passe</label>
                            <input type="password" id="password" onChange={this.handleChange} />
                        </div>
                        <div className="input-field">
                            <button className="login-form-btn">
                                Se connecter
                            </button>
                            <div className="red-text center">
                                { authError ? <p>{ authError }</p> : null}
                            </div>
                        </div>
                        <h5 style={{ paddingTop: 30 }} className="grey-text text-darken-3">Nouveau chez Localiz ?</h5>
                        <div className="input-field">
                            <NavLink className="registrer-form-btn" to='/signup'>
                                Créer votre compte Localiz
                            </NavLink>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignIn);
