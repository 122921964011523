import React from 'react';
import moment from 'moment';
import 'moment/locale/fr';

const ProductSummary = ({ product }) => {
    moment.locale('fr')
    return (
        <div className="card z-depth-0 product-summary">
            <div className="card-content grey-text text-darken-3">
                <span className="card-title">{product.title}</span>
                <p>Posté par {product.authorFirstName} {product.authorLastName}</p>
                <p className="grey-text">{moment(product.createAt.toDate()).calendar()}</p>
            </div>
        </div>
    );
}

export default ProductSummary;