import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

var firebaseConfig = {
    apiKey: "AIzaSyAkE8hQRt7_Kw8elurX8LwJ77lGBBHpHYk",
    authDomain: "localiz-236714.firebaseapp.com",
    databaseURL: "https://localiz-236714.firebaseio.com",
    projectId: "localiz-236714",
    storageBucket: "",
    messagingSenderId: "965909325377",
    appId: "1:965909325377:web:b82838e8e1b3ce38"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;